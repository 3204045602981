<template lang="html">
<div>
    <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
    </b-navbar>
    <b-container class="content-section">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row" v-show="itemShow">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-2" v-for="item in items">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <h5 class="card-title">{{item.itemName}}</h5>
                                        <p class="card-text">{{item.salePrice}} Ks</p>
                                    </div>
                                    <div class="col-md-2">
                                        <span v-if="item.isPromo == true">
                                            <p v-if="item.promoData.type == 'percent'"><i class="fas fa-tags"></i> {{item.promoData.percent}} %</p>
                                            <p v-if="item.promoData.type == 'fix'"><i class="fas fa-tags"></i> {{item.promoData.fix}} Ks</p>
                                        </span>
                                    </div>
                                    <b-col cols="8">
                                        <b-card-body class="p-0">
                                            <button class="btn btn-bg mt-2" @click="addToCart(item)">Add To Cart</button>
                                        </b-card-body>
                                    </b-col>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row status-info" v-show="cartShow">
            <div class="col-md-12" v-if="cartItems.length > 0">
                <div class="row">
                    <div class="col-md-12" >
                         <table class="table table-cart" >
                            <tr v-for="(item, index) in cartItems">
                                <td>{{item.itemName}}</td>
                                <td style="width: 100px;">
                                    <input v-model="item.qty" class="form-control input-qty" type="number" @blur="updateItem(item)">
                                </td>
                                <td class="text-right">{{item.price}} Ks</td>
                                <td>
                                    <button class="btn-delete" @click="removeItem(index)"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                            <tr>
                               <td colspan="2">Total Price</td>
                               <td colspan="2">{{Total}} Ks</td>
                            </tr>
                        </table>
                        <button class="btn btn-success"  style="width: 100%;" @click="orderItem()">To Order</button>
                        <a class="btn btn-success mt-2 text-white" style="width: 100%;"
                            @click="closeCart()">Close</a>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-else>
                <p class="text-center">Empty Cart</p>
                <a class="btn btn-success mt-2 text-white" style="width: 100%;"
                            @click="closeCart()">Close</a>
            </div>
        </div>
		<a class="float" to="" @click="showCart()" v-show="itemShow">
            <i class="fas fa-cart-plus my-float" style="padding: 37%;"></i>
            <span class="add-cart">{{cartItems.length}}</span>
		</a>
    </b-container>
</div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                itemShow: true,
                cartShow: false,
                isLoading: false,
                fullPage: true,
                message: "",
                totalPrice: "",
                items: [],
                cartItems: []
            }
        },
        computed: {
            Total() {
                let total = 0;
                this.cartItems.forEach(item => {
                    total += parseInt(item.price);
                });
                this.totalPrice = total
                return total;
            }
        },
        methods: {
            ...mapActions({
                itemListAction: 'itemListAction',
                orderStoreAction: 'orderStoreAction',
            }),
            async itemList() {
                this.isLoading = true;
                await this.itemListAction().then(res => {
                    if (res.status == "success") {
                        this.items = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            addToCart(itemToAdd) {
                const record = this.cartItems.find(p => p.id === itemToAdd.id)
                if (!record) {
                    this.cartItems.push({'id': itemToAdd.id, 'itemName': itemToAdd.itemName, 'price': itemToAdd.salePrice,'originPrice': itemToAdd.salePrice,  'qty': 1});
                } else{
                    record.qty++
                    record.price = record.qty * itemToAdd.salePrice
                }
            },
            updateItem(item) {
                const record = this.cartItems.find(p => p.id === item.id)
                record.price = record.qty * item.originPrice

            },
            removeItem(index) {
                this.cartItems.splice(index, 1)
                },
            showCart() {
                this.itemShow = false
                this.cartShow = true
            },
            closeCart() {
                this.itemShow = true
                this.cartShow = false
            },
            async orderItem() {
                this.isLoading = true
                let option = {
                    item: this.cartItems,
                    totalPrice: this.totalPrice
                }
                await this.orderStoreAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    this.$router.replace('/dashboard')
                }).catch(err => this.isLoading = true)
            }
        },
        async mounted() {
            this.itemList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 70px;
        right: 3%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }
    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 140px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }
    .add-cart {
            z-index: 999;
            position: absolute;
            color: #dc3545;
            bottom: 55%;
            right: 16%;
            font-size: 18px;
            font-weight: bolder;
    }
    .close-cart {
        z-index: 999;
        position: absolute;
        right: 5%;
        top: -2%;
        font-size: 24px;
    }
   
    .status-info {
        height: 100vh;
        background: #f8f9fa;
        z-index: 9999;
        position: absolute;
        width: 100%;
    }

    .card-scroll {
        margin-bottom: 100px;
        overflow: scroll;
    }
        /* Hide scrollbar for Chrome, Safari and Opera */
    .card-scroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .card-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }
</style>